import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faHeart, faComment } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmPrompt from '../General/ConfirmPrompt';
import './TalentResult.scss';

import { updateContract } from '../../APIClient/collaborations';
import { getUserPastRecommendationDescriptions } from '../../APIClient/recommendations';
import { getGiftingRequestsForUser } from '../../Helpers/brand_helpers';
import { rejectBrandListUserRecommendation, updateBrandListUser } from '../../APIClient/lists';
import { getFollowupTemplates } from '../../APIClient/brands';

import { setCustomRate } from '../../Helpers/rate_helpers';
import { getBrandId, isSimulatingUser } from '../../Helpers/user_helpers';
import {
  getTalentStatusEnumDetails,
  getTalentActionEnumDetails,
  getVisibleTalentActionEnum,
  getVisibleTalentStatusEnum,
  getTalentStatLevel
} from '../../Helpers/talent_helpers';
import { getAllBrandListsFoundIn } from '../../Helpers/brand_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getSmartImage } from '../../Helpers/helpers';

import BrandListsOverlay from '../General/BrandListsOverlay';
import PartnerActionTemplates from '../General/PartnerActionTemplates';
import TalentResultStats from './Elements/TalentResultStats';
import TalentResultActions from './Elements/TalentResultActions';
import SocialCountPills from '../SocialCountPills/SocialCountPills';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';

const TalentResult = props => {
  const { user, ui, analytics, openArtistModal, result, showTalentDetails, curActionEnum, curStatusEnum } = props;
  const { image, name, actionEnums, statusEnums } = result;

  // For now the talent tab only works with the most recent request object
  const requests = getGiftingRequestsForUser(analytics, result);
  const recentRequest = requests.find(r => !r.isComplete && r.userAccepted) || _.maxBy(requests, r => r.id);

  const visibleStatusEnum = getVisibleTalentStatusEnum({
    statusEnums: statusEnums.filter(se => (isAdminControlMode(ui) ? true : !se.includes('SMART_'))),
    curStatusEnum,
    curActionEnum
  });
  const visibleActionEnum = getVisibleTalentActionEnum({ actionEnums, curActionEnum, curStatusEnum });
  const sed = getTalentStatusEnumDetails(visibleStatusEnum);
  const aed = getTalentActionEnumDetails(visibleActionEnum);

  const viewTalentCard = (extra = {}) => {
    const { tab } = extra; // Allow starting on a particular tab
    openArtistModal({ ...result, tab }, () => syncTalentResult());
  };

  // Call this after any action that changes the status of the user
  const syncTalentResult = async () => props.syncTalentResult(result);

  /******************************************************************************************
   ****************************** Admin Recommendations *************************************
   ******************************************************************************************/
  const editCommentIfPossible = async () => {
    if (result.recommendation) {
      const resp = await getUserPastRecommendationDescriptions(result.recommendation.User_id);
      const pastDescriptions = resp.past.map(data => ({
        ...data,
        header: `Used ${data.count} time${data.count === 1 ? '' : 's'}, last for ${data.type}`
      }));
      confirmAlert({
        customUI: ({ onClose }) => (
          <ConfirmPrompt
            header='Add a note.'
            subheader='Explain this recommendation to the brand. This will be reusable for future recommendations for this user.'
            secondaryPanel={
              !!pastDescriptions.length && (
                <PartnerActionTemplates user={user} talent={result} templatesOne={pastDescriptions} sectionHeaderOne='Past Explanations' />
              )
            }
            onCancel={onClose}
            customInputFields={[{ value: 'description', numRows: 4, preloaded: result.recommendation.description || '' }]}
            onSubmitAwait={async responseValues => {
              const { description } = responseValues;
              await updateBrandListUser(result.recommendation, { description });
              await syncTalentResult();
            }}
          />
        )
      });
    }
  };

  /******************************************************************************************
   ************************************ Gifting *********************************************
   ******************************************************************************************/
  const sendGifting = async () => {
    props.openRequestModal({
      params: {
        preselectedUsers: formatUsersForBulkRequests([result])
      },
      submitCallback: syncTalentResult
    });
  };
  const acceptGifting = () => cogoToast.info(`acceptGifting coming soon once we enable gifting requests from users`, { hideAfter: 5 });
  const dismissGiftingRequest = () => cogoToast.info(`dismissGiftingRequest once we enable gifting requests from users`, { hideAfter: 0.5 });

  const dismissRecommendation = async () => {
    await rejectBrandListUserRecommendation({ id: result.recommendation.BrandListUser_id });
    await syncTalentResult();
  };

  const goToGiftingPortal = () => {
    props.history.push('/requests');
  };

  const markGiftingSent = () => {
    props.openFulfillmentModal({
      params: {
        Request_id: recentRequest.id
      },
      submitCallback: syncTalentResult
    });
  };
  const followUpOnGifting = async () => {
    const followupTemplatesResp = await getFollowupTemplates({
      Brand_id: getBrandId(user),
      isFollowup: true,
      User_id: result.id,
      type: 'gifting'
    });
    const { templates } = followupTemplatesResp || {};
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Add a message.'
          subheader={
            result.has_mobile_push ? `We will send this by push notification to ${result.name}.` : `We will send this by email to ${result.name}.`
          }
          secondaryPanel={
            !!templates.length && (
              <PartnerActionTemplates
                user={user}
                talent={result}
                templatesOne={templates.filter(t => t.isShopMy)}
                templatesTwo={templates.filter(t => !t.isShopMy)}
                sectionHeaderOne='Template from the ShopMy Team'
                sectionHeaderTwo='Templates from Recently Sent Messages'
              />
            )
          }
          onCancel={onClose}
          customInputFields={[{ value: 'message', placeholder: 'Write your message here', numRows: 6 }]}
          submitMustReturnTrueToClose
          onSubmitAwait={async responseValues => {
            const { message } = responseValues;
            if (!message) {
              cogoToast.warn(`You must write a message in order to follow up.`);
              return false;
            }
            await props.updateRequest(recentRequest, { hasFollowedUp: true, followUpMessage: message });
            await syncTalentResult();
            cogoToast.success(`Sent message to ${result.name}!`);
            return true;
          }}
        />
      )
    });
  };
  const dismissGiftingFollowup = async () => {
    await props.updateRequest(recentRequest, { hasDismissedFollowUp: true });
    await syncTalentResult();
  };

  /******************************************************************************************
   ************************************ Codes *********************************************
   ******************************************************************************************/
  const offerCode = async () => {
    props.openCodesModal({
      params: {
        User_id: result.id,
        name: result.name,
        showTemplates: true
      },
      closeCallback: syncTalentResult
    });
  };
  const followUpOnCode = async () => {
    const followupTemplatesResp = await getFollowupTemplates({
      Brand_id: getBrandId(user),
      User_id: result.id,
      isFollowup: true,
      type: 'code'
    });
    const { templates } = followupTemplatesResp || {};
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Add a message.'
          subheader={
            result.has_mobile_push ? `We will send this by push notification to ${result.name}.` : `We will send this by email to ${result.name}.`
          }
          secondaryPanel={
            !!templates.length && (
              <PartnerActionTemplates
                user={user}
                talent={result}
                templatesOne={templates.filter(t => t.isShopMy)}
                templatesTwo={templates.filter(t => !t.isShopMy)}
                sectionHeaderOne='Template from the ShopMy Team'
                sectionHeaderTwo='Templates from Recently Sent Messages'
              />
            )
          }
          onCancel={onClose}
          customInputFields={[{ value: 'message', placeholder: 'Write your message here', numRows: 6 }]}
          submitMustReturnTrueToClose
          onSubmitAwait={async responseValues => {
            const { message } = responseValues;
            if (!message) {
              cogoToast.warn(`You must write a message in order to follow up.`);
              return false;
            }
            await props.updateCode(result.code, { hasFollowedUp: true, followUpMessage: message });
            await syncTalentResult();
            cogoToast.success(`Sent message to ${result.name}!`);
            return true;
          }}
        />
      )
    });
  };
  const dismissCodeFollowup = async () => {
    await props.updateCode(result.code, { hasDismissedFollowUp: true });
    await syncTalentResult();
  };

  /******************************************************************************************
   ************************************** Rates *********************************************
   ******************************************************************************************/
  const adjustRate = () => {
    setCustomRate({
      user,
      artist: result,
      analytics: props.analytics,
      setCustomCommissionRate: props.setCustomCommissionRate,
      closeCallback: async () => {
        await syncTalentResult();
      }
    });
  };

  /******************************************************************************************
   *********************************** Opportunities ****************************************
   ******************************************************************************************/
  const sendOpportunity = async () => {
    props.openRequestModal({
      params: {
        type: 'opportunities',
        preselectedUsers: formatUsersForBulkRequests([result])
      },
      submitCallback: syncTalentResult
    });
  };

  /******************************************************************************************
   ********************************** Collaboration *******************************************
   ******************************************************************************************/
  const proposeCollaboration = () => {
    viewTalentCard({ tab: 'collabs' });
  };
  const viewCollaboration = () => {
    window.location.href = `/collaboration/${result.contract.id}`;
  };
  const dismissCollaboration = async () => {
    await updateContract(result.contract, { status: 'rejected' });
    await syncTalentResult();
  };

  /******************************************************************************************
   ************************************* Chats ***********************************************
   ******************************************************************************************/
  const openChat = () => props.openChatOverlay(result);
  const replyToChat = () => {
    if (recentRequest && result.address && window.getSelection().type === 'Range') return; // Highlighting address
    props.openChatOverlay(result);
  };
  const markChatRead = async () => {
    await props.updateChat(result.chat, { hasNewMessagesForBrand: false });
    await syncTalentResult();
  };
  const dismissChat = async () => {
    await props.updateChat(result.chat, { isDismissedByBrand: true, hasNewMessagesForBrand: false });
    await syncTalentResult();
  };

  /******************************************************************************************
   ************************************ Badges ********************************************
   ******************************************************************************************/
  let badges = [];
  const sales = getTalentStatLevel(result, 'monthly_order_volume');
  const following = getTalentStatLevel(result, 'social_following');

  // Order matters, only showing the first
  ['highest'].includes(sales) && badges.push({ display: 'Strong Sales Driver' });
  ['highest', 'higher'].includes(following) && badges.push({ display: 'Large Social Following' });

  // For Testing
  // badges.push({ display: `${result.score.toFixed(1)}` });

  /******************************************************************************************
   ************************************ Comments ********************************************
   ******************************************************************************************/
  let comment, commentAuthor;
  if (sed.getComment) {
    comment = sed.getComment(result);
    commentAuthor = sed.getCommentAuthor && sed.getCommentAuthor(result);
  } else if (result.recommendation?.description) {
    comment = result.recommendation.description;
  }
  const canAddComment = sed?.section === 'recommending' && !sed?.isAdmin && isSimulatingUser(user);
  const additionalClasses = { details: showTalentDetails };

  /******************************************************************************************
   *************************************** Lists ********************************************
   ******************************************************************************************/
  const [isSelectingBrandList, setIsSelectingBrandList] = useState(false);
  const inBrandLists = getAllBrandListsFoundIn(user, result, { hideRecommendationLists: !isAdminControlMode(ui) });
  const isInList = !!inBrandLists.length;

  const logResult = () => window.__ADMIN_CONTROL_MODE__ && console.info({ result, recentRequest });
  return (
    <div onClick={logResult} className={cn('talent-result-outer-container', { alert: aed.showAlert })}>
      <div className={cn('image-container', additionalClasses)}>
        {image ? (
          <img onClick={openChat} alt={name} src={getSmartImage(image)} />
        ) : (
          <div onClick={openChat} className='empty-img'>
            S
          </div>
        )}
        <div className='action-btns'>
          <div onClick={openChat} className='action-btn chat-btn'>
            <FontAwesomeIcon icon={faComment} />
          </div>
          <BrandListsOverlay
            syncAfterEdit={syncTalentResult}
            closeOverlay={() => setIsSelectingBrandList(false)}
            selectedUser_id={result.id}
            isActive={isSelectingBrandList}
          >
            <div onClick={() => setIsSelectingBrandList(true)} className={cn('action-btn select-list', { active: isInList })}>
              {isInList ? <FontAwesomeIcon icon={faHeartSolid} /> : <FontAwesomeIcon icon={faHeart} />}
            </div>
          </BrandListsOverlay>
        </div>
      </div>
      <div className='body'>
        <div className='body-inner'>
          <div className='main'>
            <div className='content'>
              <div className={cn('info', additionalClasses)}>
                <div onClick={syncTalentResult} className='badge'>
                  {sed.display}
                </div>
                <div onClick={viewTalentCard} className='name'>
                  {name}
                </div>
                <div onClick={viewTalentCard} className='open-card-btn'>
                  View Talent Card
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <SocialCountPills user={result} theme={'dark'} size={'small'} />
                {!!badges.length && (
                  <div className='badges'>
                    {badges.slice(0, 1).map((badge, i) => (
                      <div key={badge.display} className='badge-container'>
                        {badge.display}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {showTalentDetails && (
              <div className='stats'>
                <TalentResultStats result={result} user={user} ui={props.ui} rows={2} />
              </div>
            )}
          </div>
          <div className={cn('actions', additionalClasses)}>
            <TalentResultActions
              user={user}
              ui={ui}
              analytics={props.analytics}
              result={result}
              recentRequest={recentRequest}
              sendGifting={sendGifting}
              sendOpportunity={sendOpportunity}
              offerCode={offerCode}
              curListId={props.curListId}
              curActionEnum={curActionEnum}
              curStatusEnum={curStatusEnum}
              dismissRecommendation={dismissRecommendation}
              dismissGiftingRequest={dismissGiftingRequest}
              markGiftingSent={markGiftingSent}
              goToGiftingPortal={goToGiftingPortal}
              followUpOnGifting={followUpOnGifting}
              followUpOnCode={followUpOnCode}
              acceptGifting={acceptGifting}
              dismissGiftingFollowup={dismissGiftingFollowup}
              dismissCodeFollowup={dismissCodeFollowup}
              proposeCollaboration={proposeCollaboration}
              viewCollaboration={viewCollaboration}
              dismissCollaboration={dismissCollaboration}
              replyToChat={replyToChat}
              dismissChat={dismissChat}
              markChatRead={markChatRead}
              adjustRate={adjustRate}
              syncTalentResult={syncTalentResult}
            />
          </div>
        </div>
        {comment ? (
          <div onClick={result.recommendation ? editCommentIfPossible : replyToChat} className='body-footer'>
            <div className='comment'>{comment}</div>
            <div className='comment-author'>
              {commentAuthor?.image ? <img alt={commentAuthor.name} src={commentAuthor.image} /> : <div className='logo'>S</div>}
            </div>
          </div>
        ) : (
          canAddComment && (
            <div className='add-comment-btn' onClick={editCommentIfPossible}>
              Add Explainer
            </div>
          )
        )}
      </div>
    </div>
  );
};

TalentResult.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  curListId: PropTypes.number,
  curActionEnum: PropTypes.string,
  curStatusEnum: PropTypes.string,
  showTalentDetails: PropTypes.bool.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  syncTalentResult: PropTypes.func.isRequired,
  augmentBrandLists: PropTypes.func.isRequired,
  createSamplesRequest: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  setCustomCode: PropTypes.func.isRequired,
  updateCode: PropTypes.func.isRequired,
  setCustomCommissionRate: PropTypes.func.isRequired,
  updateChat: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired
};

export default TalentResult;
