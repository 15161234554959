import { getAdjPayoutRate, getAmazonCode } from './user_helpers';
import { getRateDisplay } from './formatting';

export const getDisclaimerForMerchant = (merchant, user) => {
  if (!merchant) return null;

  if (merchant.source === 'shopmyshelf') {
    const brand = JSON.parse(merchant.raw);
    if (brand.commission_rate_returning) {
      const adjRate = getAdjPayoutRate(user, merchant);
      const adjRateDisplay = getRateDisplay(adjRate, merchant);
      const returningRateDisplay = getRateDisplay(brand.commission_rate_returning, merchant);
      const returningRateDisclaimer = `The rate of ${adjRateDisplay} applies only to new customers. For returning customers you will receive a rate of ${returningRateDisplay}.`;
      return merchant.disclaimer ? `${merchant.disclaimer}. ${returningRateDisclaimer}` : returningRateDisclaimer;
    }
  }

  return merchant?.disclaimer || null;
};

export const getMerchantHomeUrl = merchant => {
  if (merchant.domain === 'aliceandolivia.com') {
    return `https://www.${merchant.domain}`;
  }

  return `https://${merchant.domain}`;
};

export const isAmazonProgramLink = (link, user) => {
  /*
    Amazon Associates links can be identified by the following:
      - amzn.to (Short Links)
      - amazon.com with a tag query parameter (Long Links)
  */
  if (link.includes('amzn.to')) return true;
  if (link.includes('amazon.') && link.includes('tag=')) return true;
  if (link.includes('amazon.') && getAmazonCode(user)) return true;
  return false;
};
